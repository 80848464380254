import React, { forwardRef } from 'react';
import { Paper, Stack } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';

const PreviewBioAge = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, wrapperSx, previewerState, widgetProps }, ref) => {
    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <PreviewFeatureTitle
          tFunction={tFunction}
          palette={palette}
          title={
            widgetProps?.preferences?.title?.[previewerState.selectedLocale as string] ??
            widgetProps?.preferences?.title?.en_US ??
            'appPreviewer.labels.analysis'
          }
        />
        <Paper
          sx={{
            textAlign: 'left',
            fontSize: '14px',
            color: 'rgb(33,33,33)',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
          }}
        >
          <Stack direction="row" justifyContent="space-between" sx={{ height: '115px' }}>
            <Stack
              sx={{ height: '100%', boxSizing: 'border-box', pl: '15px', pb: '15px' }}
              gap="12px"
              justifyContent="center"
            >
              <Stack flexDirection="row" alignItems="center" gap="4px">
                <span
                  style={{
                    fontSize: '40px',
                    fontWeight: 700,
                    lineHeight: '48px',
                    letterSpacing: '0.37px',
                    color: 'rgb(41, 41, 41)',
                  }}
                >
                  35
                </span>
                <span
                  style={{
                    fontSize: '28px',
                    fontWeight: 700,
                    letterSpacing: '0.37px',
                    lineHeight: '34px',
                    color: 'rgb(92, 92, 92)',
                  }}
                >
                  {tFunction('appPreviewer.labels.year_other').toLowerCase()}
                </span>
              </Stack>
              <span style={{ fontSize: '10px', lineHeight: '12px', color: 'rgb(92, 92, 92)' }}>
                Updated on Sep 12, 2023
              </span>
            </Stack>
            <img
              style={{ width: '139px', height: '115px' }}
              src="/images/previewerDefaultImages/features/femaleBioAge.png"
              alt="bio age"
            />
          </Stack>
        </Paper>
      </PreviewContainer>
    );
  },
);

export default PreviewBioAge;
