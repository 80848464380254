import React from 'react';
import { Stack } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import CategoriesItem from './CategoriesItem';
import { bioAgeItemsMock } from './data';

const CategoriesList: React.FC<AppLayoutFeatureComponentProps> = ({ tFunction, palette }) => {
  return (
    <Stack spacing="16px">
      {bioAgeItemsMock.map(it => {
        return <CategoriesItem tFunction={tFunction} palette={palette} key={it.id} {...it} />;
      })}
    </Stack>
  );
};

export default CategoriesList;
