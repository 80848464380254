import React, { ReactNode } from 'react';
import { TFunction } from 'i18next';
import { Icons, Stack } from '@egym/ui';
import { AppPreviewerPalette } from '../../../../AppPreviewerProps';

type Props = {
  tFunction: TFunction;
  palette: Partial<AppPreviewerPalette>;
  name: string;
  years: number;
  image: string;
  bgColor: string;
};

const CategoriesItem: React.FC<Props> = ({ tFunction, name, years, image, bgColor }) => {
  return (
    <Stack
      sx={{
        borderRadius: '8px',
        height: '105px',
        px: '15px',
        background: `url(${image}) no-repeat right`,
        backgroundColor: bgColor,
      }}
    >
      <Stack sx={{ py: '16px' }} justifyContent="space-between" flex={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <span style={{ color: 'white', fontSize: '16px', lineHeight: '20px', fontWeight: 600 }}>
            {tFunction(name) as ReactNode}
          </span>
          <Icons.KeyboardArrowRight fontSize="medium" htmlColor="white" />
        </Stack>
        <span style={{ color: 'white' }}>
          <span
            style={{
              fontSize: '24px',
              fontWeight: 600,
              letterSpacing: '-0.5px',
              marginRight: '5px',
            }}
          >
            {years}
          </span>
          <span style={{ fontSize: '16px', fontWeight: 500, letterSpacing: '-0.5px', textTransform: 'lowercase' }}>
            {tFunction('appPreviewer.labels.year', { count: years }) as ReactNode}
          </span>
        </span>
      </Stack>
    </Stack>
  );
};

export default CategoriesItem;
