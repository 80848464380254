import React, { forwardRef } from 'react';
import { alpha } from '@mui/material/styles';
import { IconButton, Stack, useFormatDate } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import { IconWrapper, IntelligentAssistantIcon, InfoOutlinedIcon } from '../../../Icons';
import IntelligentAssistantPreviewer from '../../../Icons/IntelligentAssistantPreviewer';
import PreviewContainer from '../../../PreviewContainer';
import CategoriesList from './CategoriesList';

const PreviewBioAgeOnTheTab = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, previewerState, wrapperSx }, ref) => {
    const { format } = useFormatDate(previewerState.selectedLocale);

    return (
      <div ref={ref}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{
            background:
              'url(/images/previewerDefaultImages/features/analysis/femaleBioAge@1x.png) no-repeat right/180px',
            backgroundColor: 'white',
          }}
        >
          <Stack flexDirection="row" justifyContent="space-between" flex={1} px="15px">
            <Stack paddingTop="20px" paddingBottom="20px" alignItems="flex-start" gap="12px">
              <span
                style={{
                  textAlign: 'center',
                  color: 'rgb(41, 41, 41)',
                  fontSize: '40px',
                  lineHeight: '48px',
                  fontWeight: 700,
                  backgroundColor: 'transparent',
                }}
              >
                BioAge
              </span>
              <Stack flexDirection="row" alignItems="center" justifyContent="center" gap="4px">
                <span
                  style={{
                    textAlign: 'center',
                    color: 'rgb(41, 41, 41)',
                    fontSize: '28px',
                    lineHeight: '34px',
                    fontWeight: 700,
                    backgroundColor: 'transparent',
                  }}
                >
                  35
                </span>
                <span
                  style={{
                    fontSize: '20px',
                    fontWeight: 600,
                    lineHeight: '25px',
                    color: 'rgb(92, 92, 92)',
                    textTransform: 'lowercase',
                  }}
                >
                  {tFunction('appPreviewer.labels.year_other')}
                </span>
              </Stack>
              <IconButton
                size="small"
                sx={{
                  padding: '8px',
                  background: palette.primary ? alpha(palette.primary, 0.1) : undefined,
                  color: 'rgb(41, 41, 41 )',
                  fontSize: '16px',
                  fontWeight: 500,
                  borderRadius: '8px',
                  gap: '4px',
                }}
              >
                <IntelligentAssistantIcon htmlColor={palette.primary} />
                {tFunction('appPreviewer.labels.assistance')}
              </IconButton>
            </Stack>
            <IconWrapper bgColor="rgb(245, 245, 245)">
              <InfoOutlinedIcon htmlColor="black" />
            </IconWrapper>
          </Stack>
        </Stack>
        <PreviewContainer
          wrapperSx={{
            ...wrapperSx,
            boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
            borderTop: 1,
            borderColor: 'rgb(232, 232, 232)',
            backgroundColor: 'white',
            py: '20px',
          }}
        >
          <Stack>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
              <span
                style={{
                  color: 'rgba(41, 41, 41)',
                  fontSize: '20px',
                  fontWeight: 700,
                }}
              >
                {tFunction('appPreviewer.labels.history')}
              </span>
              <span
                style={{
                  fontSize: '10px',
                  lineHeight: '12px',
                  color: 'rgb(92, 92, 92)',
                }}
              >
                {tFunction('appPreviewer.labels.updatedOn', { dateString: format(new Date(), 'PP') })}
              </span>
            </Stack>
            <img
              style={{ height: '120px' }}
              src="/images/previewerDefaultImages/features/analysis/historyGraph.png"
              alt="diamond"
            />
            <div
              style={{
                background: 'rgb(245, 245, 245)',
                padding: '8px',
                fontSize: '16px',
                fontWeight: 500,
                borderRadius: '8px',
                textAlign: 'center',
                color: palette.primary,
              }}
            >
              {tFunction('appPreviewer.labels.showAllHistory')}
            </div>
          </Stack>
        </PreviewContainer>

        <Stack
          sx={{
            mx: '15px',
            my: '24px',
          }}
          spacing="24px"
        >
          <Stack spacing="12px">
            <span
              style={{
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '25px',
                color: 'rgb(41, 41, 41)',
              }}
            >
              {tFunction('appPreviewer.labels.information')}
            </span>
            <Stack
              direction="row"
              justifyContent="space-between"
              bgcolor="white"
              borderRadius="8px"
              sx={{
                py: '16px',
                pl: '20px',
              }}
            >
              <Stack flex="1" spacing="12px">
                <span
                  style={{
                    fontSize: '20px',
                    fontWeight: 600,
                    lineHeight: '25px',
                    color: 'rgb(41, 41, 41)',
                  }}
                >
                  {tFunction('appPreviewer.labels.virtualAssistanceTitle')}
                </span>
                <span
                  style={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: 'rgb(41, 41, 41)',
                  }}
                >
                  {tFunction('appPreviewer.labels.virtualAssistanceDesc')}
                </span>
                <div
                  style={{
                    background: 'rgb(245, 245, 245)',
                    padding: '8px',
                    fontSize: '16px',
                    fontWeight: 500,
                    borderRadius: '8px',
                    textAlign: 'center',
                    color: palette.primary,
                  }}
                >
                  {tFunction('appPreviewer.labels.readMore')}
                </div>
              </Stack>

              <IntelligentAssistantPreviewer htmlColor={palette.primary} />
            </Stack>
          </Stack>
          <Stack spacing="12px">
            <span
              style={{
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '25px',
                color: 'rgb(41, 41, 41)',
              }}
            >
              {tFunction('appPreviewer.labels.categories')}
            </span>
            <CategoriesList tFunction={tFunction} palette={palette} previewerState={previewerState} />
          </Stack>
        </Stack>
      </div>
    );
  },
);

export default PreviewBioAgeOnTheTab;
