export const bioAgeItemsMock = [
  {
    id: 0,
    name: 'appPreviewer.labels.strength',
    years: 21,
    image: '/images/previewerDefaultImages/features/analysis/strength.png',
    bgColor: '#C75300',
  },
  {
    id: 1,
    name: 'appPreviewer.labels.flexibility',
    years: 23,
    image: '/images/previewerDefaultImages/features/analysis/flexibility.png',
    bgColor: '#19808E',
  },
  {
    id: 2,
    name: 'appPreviewer.labels.metabolism',
    years: 25,
    image: '/images/previewerDefaultImages/features/analysis/metabolism.png',
    bgColor: '#AC45DE',
  },
  {
    id: 3,
    name: 'appPreviewer.labels.cardio',
    years: 21,
    image: '/images/previewerDefaultImages/features/analysis/cardio.png',
    bgColor: '#DB3350',
  },
];
