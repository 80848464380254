import React from 'react';
import { SvgIconProps } from '@mui/material';

const IntelligentIcon: React.FC<SvgIconProps> = ({ htmlColor = '#000' }) => {
  return (
    <svg width="97" height="123" viewBox="0 0 97 123" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.2">
        <g filter="url(#filter0_i_0_17569)">
          <circle cx="61.5" cy="61.5" r="61.5" fill="white" fillOpacity="0.01" />
        </g>
        <circle cx="61.5" cy="61.5" r="61" stroke={htmlColor} />
      </g>
      <g opacity="0.5">
        <g filter="url(#filter1_i_0_17569)">
          <circle cx="61.5002" cy="61.4993" r="45.2864" fill="white" fillOpacity="0.01" />
        </g>
        <circle cx="61.5002" cy="61.4993" r="44.7864" stroke={htmlColor} />
      </g>
      <g opacity="0.8">
        <g filter="url(#filter2_i_0_17569)">
          <circle cx="61.4999" cy="61.4994" r="25.7182" fill="white" fillOpacity="0.01" />
        </g>
        <circle cx="61.4999" cy="61.4994" r="25.2182" stroke={htmlColor} />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.2947 61.4999C65.2433 61.4999 64.3934 60.6501 64.3934 59.5987V57.6975V46.2902C64.3934 45.2389 63.5436 44.389 62.4922 44.389C61.4409 44.389 60.591 45.2389 60.591 46.2902V55.7963C60.591 56.8477 59.7412 57.6975 58.6898 57.6975C57.6384 57.6975 56.7886 56.8477 56.7886 55.7963C56.7886 54.7449 55.9388 53.8951 54.8874 53.8951C53.836 53.8951 52.9862 54.7449 52.9862 55.7963V57.6975V61.4999V74.8084C52.9862 75.8598 53.836 76.7096 54.8874 76.7096C55.9388 76.7096 56.7886 75.8598 56.7886 74.8084V63.4011C56.7886 62.3498 57.6384 61.4999 58.6898 61.4999C59.7412 61.4999 60.591 62.3498 60.591 63.4011V65.3023V76.7096C60.591 77.761 61.4409 78.6108 62.4922 78.6108C63.5436 78.6108 64.3934 77.761 64.3934 76.7096V67.2036C64.3934 66.1522 65.2433 65.3023 66.2947 65.3023C67.346 65.3023 68.1959 66.1522 68.1959 67.2036V69.1048C68.1959 70.1561 69.0457 71.006 70.0971 71.006C71.1484 71.006 71.9983 70.1561 71.9983 69.1048V65.3023V61.4999V53.8951C71.9983 52.8437 71.1484 51.9939 70.0971 51.9939C69.0457 51.9939 68.1959 52.8437 68.1959 53.8951V59.5987C68.1959 60.6501 67.346 61.4999 66.2947 61.4999ZM75.8007 55.7963V59.5987C75.8007 60.6501 76.6505 61.4999 77.7019 61.4999C78.7533 61.4999 79.6031 60.6501 79.6031 59.5987V55.7963C79.6031 54.7449 78.7533 53.8951 77.7019 53.8951C76.6505 53.8951 75.8007 54.7449 75.8007 55.7963ZM47.2826 69.1048C48.3339 69.1048 49.1838 68.2549 49.1838 67.2036V59.5987C49.1838 58.5473 48.3339 57.6975 47.2826 57.6975C46.2312 57.6975 45.3813 58.5473 45.3813 59.5987V67.2036C45.3813 68.2549 46.2312 69.1048 47.2826 69.1048Z"
        fill={htmlColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.2947 61.4999C65.2433 61.4999 64.3934 60.6501 64.3934 59.5987V57.6975V46.2902C64.3934 45.2389 63.5436 44.389 62.4922 44.389C61.4409 44.389 60.591 45.2389 60.591 46.2902V55.7963C60.591 56.8477 59.7412 57.6975 58.6898 57.6975C57.6384 57.6975 56.7886 56.8477 56.7886 55.7963C56.7886 54.7449 55.9388 53.8951 54.8874 53.8951C53.836 53.8951 52.9862 54.7449 52.9862 55.7963V57.6975V61.4999V74.8084C52.9862 75.8598 53.836 76.7096 54.8874 76.7096C55.9388 76.7096 56.7886 75.8598 56.7886 74.8084V63.4011C56.7886 62.3498 57.6384 61.4999 58.6898 61.4999C59.7412 61.4999 60.591 62.3498 60.591 63.4011V65.3023V76.7096C60.591 77.761 61.4409 78.6108 62.4922 78.6108C63.5436 78.6108 64.3934 77.761 64.3934 76.7096V67.2036C64.3934 66.1522 65.2433 65.3023 66.2947 65.3023C67.346 65.3023 68.1959 66.1522 68.1959 67.2036V69.1048C68.1959 70.1561 69.0457 71.006 70.0971 71.006C71.1484 71.006 71.9983 70.1561 71.9983 69.1048V65.3023V61.4999V53.8951C71.9983 52.8437 71.1484 51.9939 70.0971 51.9939C69.0457 51.9939 68.1959 52.8437 68.1959 53.8951V59.5987C68.1959 60.6501 67.346 61.4999 66.2947 61.4999ZM75.8007 55.7963V59.5987C75.8007 60.6501 76.6505 61.4999 77.7019 61.4999C78.7533 61.4999 79.6031 60.6501 79.6031 59.5987V55.7963C79.6031 54.7449 78.7533 53.8951 77.7019 53.8951C76.6505 53.8951 75.8007 54.7449 75.8007 55.7963ZM47.2826 69.1048C48.3339 69.1048 49.1838 68.2549 49.1838 67.2036V59.5987C49.1838 58.5473 48.3339 57.6975 47.2826 57.6975C46.2312 57.6975 45.3813 58.5473 45.3813 59.5987V67.2036C45.3813 68.2549 46.2312 69.1048 47.2826 69.1048Z"
        fill="url(#paint0_radial_0_17569)"
      />
      <defs>
        <filter
          id="filter0_i_0_17569"
          x="0"
          y="0"
          width="123"
          height="123"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="31.7419" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.890196 0 0 0 0 0.827451 0 0 0 0 0.866667 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_0_17569" />
        </filter>
        <filter
          id="filter1_i_0_17569"
          x="16.2139"
          y="16.2129"
          width="90.5728"
          height="90.5728"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="31.7419" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.890196 0 0 0 0 0.827451 0 0 0 0 0.866667 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_0_17569" />
        </filter>
        <filter
          id="filter2_i_0_17569"
          x="35.7817"
          y="35.7812"
          width="51.4365"
          height="51.4364"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="31.7419" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.890196 0 0 0 0 0.827451 0 0 0 0 0.866667 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_0_17569" />
        </filter>
        <radialGradient
          id="paint0_radial_0_17569"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(87.0426 41.9092) rotate(88.9205) scale(48.999 86.4846)"
        >
          <stop offset="0.234347" stopColor="white" stopOpacity="0.75" />
          <stop offset="1" stopColor="white" stopOpacity="0.02" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default IntelligentIcon;
